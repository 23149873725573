<template>
    <div>
        <header-entry>联系商家阅读</header-entry>
        <div class="content-box">
            <div class="content-text-gray">操作方法：请复制资料名称+手机号码发送给商家购买。</div>
            <div class="content-title">手机号码：</div>
            <div class="content-text-gray">{{ userPhone }}</div>
            <div class="content-title">资料名称：</div>
            <div class="content-text-gray">{{ documentName }}</div>
            <div class="content-price">全国统一零售价：￥{{ documentPrice }}</div>
            <div class="content-center">
                <el-button type="primary" @click="onCopy">复制资料名称和手机号码</el-button>
            </div>
        </div>
        <div class="content-line"></div>
        <header-entry>商家联系方式</header-entry>
        <div class="content-box">
            <div class="content-text-black">温馨提示：购买后请在我的资料中阅读使用！</div>
        </div>
        <div class="content-center">
            <div class="content-text-black">① 扫码添加好友，联系商家购买</div>
            <img class="content-img" :src="proxyContactImg" />
            <div class="content-text-black">② 商家联系方式如下：</div>
            <div class="content-text-black">{{ proxyContactInfo }}</div>
        </div>
        <div class="content-line"></div>
    </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        HeaderEntry,
    },
    data() {
        return {
            userPhone: null,
            proxyContactInfo: null,
            documentGuid: null,
            documentName: null,
            documentPrice: null,
        };
    },
    computed: {
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
    },
    methods: {
        getRoutParams() {
            this.documentGuid = this.$route.query.documentGuid;
        },
        getUserInfo() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            this.$axios
                .post(Config.user.info, model)
                .then((res) => {
                    this.userPhone = res.data.Data.UserPhone;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.proxy.contactInfo, model)
                .then((res) => {
                    this.proxyContactInfo = res.data.Data.ContactInfo;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        getDocumentInfo() {
            var model = {
                DocumentGuid: this.documentGuid,
            };
            this.$axios
                .post(Config.document.info, model)
                .then((res) => {
                    this.documentName = res.data.Data.DocumentName;
                    this.documentPrice = res.data.Data.DocumentPrice.toFixed(2);
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        onCopy() {
            try {
                var that = this;
                var content = `购买信息\n资料名称：${this.documentName}\n手机号码：${this.userPhone}`;
                this.$copyText(content).then(
                    function (e) {
                        that.$message.success("复制成功。");
                    },
                    function (e) {
                        that.$message.error("复制失败。" + e.message);
                    }
                );
            } catch (error) {
                this.$message.error(error.message);
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getUserInfo();
        this.getProxyInfo();
        this.getDocumentInfo();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}

.content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.content-img {
    width: 200px;
    height: 200px;
}

.content-title {
    color: #1E1E1E;
    font-size: 16px;
    margin: 10px 0;
}

.content-text-gray {
    color: gray;
    font-size: 14px;
    margin: 10px 0;
}

.content-text-black {
    color: #1E1E1E;
    font-size: 14px;
    margin: 10px 0;
}

.content-price {
    color: #FE5E03;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
}

.content-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}
</style>